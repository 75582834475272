<mat-toolbar
  *ngIf="bottomService.visible"
  class="w-100 nav white-color d-flex px-0 display-mobiles-only"
>
  <a
    *ngFor="let icon of icons"
    class="d-flex flex-column align-items-center justify-content-center nav_link black-color"
    (click)="scrollToTop()"
  >
    <button
      mat-icon-button
      class="fs-24 pointer"
      [ngClass]="{ 'hide-icon': icon.iconName === 'add_circle' }"
      *ngIf="icon.iconName !== 'notifications' && icon.iconName !== 'search'"
      [routerLink]="
        icon.iconName === 'account_circle' && !loggedIn()
          ? '/login'
          : icon.routerLink
      "
      [routerLinkActive]="['active-link']"
    >
      <mat-icon class="material-icons-outlined">{{ icon.iconName }}</mat-icon>
    </button>

    <button
      mat-icon-button
      class="fs-24 pointer"
      *ngIf="icon.iconName === 'notifications'"
      routerLink="/{{ icon.routerLink }}"
      [routerLinkActive]="['active-link']"
      (click)="updateNotifications()"
    >
      <mat-icon
        class="material-icons-outlined"
        matBadge="{{ unseenNotifications }}"
        matBadgeHidden="{{ unseenNotifications === 0 }}"
      >
        {{ icon.iconName }}
      </mat-icon>
    </button>

    <button
      mat-icon-button
      class="fs-24 pointer"
      *ngIf="icon.iconName === 'search'"
      [routerLinkActive]="['active-link']"
      (click)="icon.onclick ? icon.onclick() : null"
    >
      <mat-icon class="material-icons-outlined">{{ icon.iconName }}</mat-icon>
    </button>
  </a>
</mat-toolbar>

<div *ngIf="showSearchCard">
  <app-search></app-search>
</div>
