import { NotificationService } from './../../../core/service/notificationService/notification.service';
import { BottomNavBarService } from './../../../core/service/bottomNavBar/bottom-nav-bar.service';
import { Component, OnInit } from '@angular/core';
import { WebSocketService } from './../../../core/service/websocketService/websocket.service';
import { DataTransferService } from '../../../core/service/data-transfer/data-transfer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bottom-nav-bar-lister',
  templateUrl: './bottom-nav-bar-lister.component.html',
  styleUrls: ['./bottom-nav-bar-lister.component.scss'],
})
export class BottomNavBarListerComponent implements OnInit {
  unseenNotifications!: any;
  showSearchCard!: boolean;
  ngOnInit(): void {
    this.notificationService.fetchNotificationsCount().subscribe((response) => {
      this.unseenNotifications = response.body?.responseBody;
    });
    this.webSocketService.updateNotificationSubject$.subscribe(() => {
      this.notificationService
        .fetchNotificationsCount()
        .subscribe((response) => {
          this.unseenNotifications = response.body.responseBody;
        });
      this.dataTransferService.enableSearchCard.subscribe((value) => {
        this.showSearchCard = value;
      });
    });
  }
  constructor(
    public bottomService: BottomNavBarService,
    private notificationService: NotificationService,
    private webSocketService: WebSocketService,
    private dataTransferService: DataTransferService,
    private router: Router,
  ) {}
  toggleSearchCard() {
    this.login();
    this.showSearchCard = true;
  }
  icons = [
    {
      iconName: 'home',
      routerLink: '/home',
    },
    {
      iconName: 'search',
      onclick: () => this.toggleSearchCard(),
    },
    {
      iconName: 'add_circle',
      routerLink: 'null',
    },
    {
      iconName: 'notifications',
      routerLink: 'notification',
    },
    {
      iconName: 'account_circle',
      routerLink: '/account',
    },
  ];

  loggedIn() {
    return this.dataTransferService.isLoggedin();
  }

  updateNotifications() {
    this.login();
    this.notificationService.markAsAllRead().subscribe((response: any) => {
      if (response.responseCode == 200) {
        this.unseenNotifications = 0;
      }
    });
  }

  login() {
    if (!this.loggedIn()) {
      return this.router.navigate(['/login']);
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
