import { TokenService } from './../../core/service/tokenService/token.service';
import { LoaderService } from './../../core/service/loader/loader.service';
import { HeaderService } from './../../core/service/header/header.service';
import { ForgotPasswordComponent } from './../forgot-password/forgot-password.component';
import { BottomNavBarService } from './../../core/service/bottomNavBar/bottom-nav-bar.service';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { UserService } from './../../core/service/userService/user.service';
import {
  AfterContentInit,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from './../../core/service/snackBar/snackbar.service';
import { OtpTransferComponent } from '../otp-transfer/otp-transfer.component';
import { OtpTimerService } from './../../core/service/otpTimer/otp-timer.service';
import { isPlatformBrowser } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CreateAScreamComponent } from '../../scream/create-a-scream/create-a-scream.component';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements AfterContentInit {
  showResendOtpButton = false;
  countdown = 60;
  interval: any;
  errorMessage!: string;
  isHidePassword = true;
  loginUser: any;
  otp!: string;
  inputDigitLeft: string = 'Verify code';
  btnStatus: string = 'btn-light';
  platformId: Object;
  localTime: any;
  deviceInfo: any;
  os: any;
  deviceType: any;
  previousUrl: any;
  currentUrl: any;
  isScreamDialogOpen: boolean = false;
  constructor(
    private readonly userService: UserService,
    public router: Router,
    public dataTransferService: DataTransferService,
    public readonly bottomService: BottomNavBarService,
    public readonly headerService: HeaderService,
    public dialog: MatDialog,
    public loaderService: LoaderService,
    private readonly snackBarService: SnackbarService,
    private tokenService: TokenService,
    private otpTimerService: OtpTimerService,
    @Inject(PLATFORM_ID) platformId: Object,
    private deviceDetectorService: DeviceDetectorService,
  ) {
    this.platformId = platformId;
  }

  ngAfterContentInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.hideHeader();
    }
    setTimeout(() => {
      this.bottomService.hide();
    }, 0);
  }
  hideHeader() {
    if (window.screen.width < 577) {
      setTimeout(() => {
        this.headerService.hide();
        this.bottomService.hide();
      }, 0);
    }
  }
  loginForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
    ]),
    password: new FormControl('', [Validators.required]),
  });
  openForgotPasswordDialog() {
    this.dialog.open(ForgotPasswordComponent, {
      panelClass: ['dialog-box'],
    });
  }
  loginUserBody() {
    return {
      signUpType: 'SCREMER',
      userAccount: {
        email: this.loginForm.value['email'],
        password: this.loginForm.value['password'],
      },
      logDTO: {
        operatingSystem: this.deviceDetectorService.getDeviceInfo().os,
        deviceType: this.deviceDetectorService.getDeviceInfo().deviceType,
      },
    };
  }

  login() {
    if (this.loginForm.valid) {
      this.loaderService.show();
      this.userService.loginUser(this.loginUserBody()).subscribe({
        next: (response) => {
          if (response.body.responseCode == 200) {
            this.loaderService.hide();
            const userDetails = response.body.responseBody;
            this.headerService.updateImage(userDetails?.profileImage);
            this.dataTransferService.setLoginToken(response.body.token);
            this.dataTransferService.setUserDetails(
              JSON.stringify(userDetails) || '',
            );
            const redirectTo =
              this.dataTransferService.getQRCodeUrl() || '/home';
            this.router.navigateByUrl(redirectTo);
            this.dataTransferService.removeQrCodeUrl();
            if (redirectTo == '/home') {
              this.checkForDraft();
            }
          }
        },
        error: (data) => {
          this.loaderService.hide();
          if (data.error.responseCode == 201) {
            this.tokenService.setToken(data.error.token);
            this.tokenService.setContent(false);
            this.tokenService.setPurpose('sign-up');
            this.dialog.open(OtpTransferComponent, {
              panelClass: ['full-screen-modal'],
            });
            this.otpTimerService.startTimer();
          } else {
            if (data.status == 0) {
              this.snackBarService.openSnackBar(
                'Internal server error. Please try again!',
                'error-snackbar',
              );
            } else {
              this.snackBarService.openSnackBar(
                'Invalid email or Password',
                'error-snackbar',
              );
            }
          }
        },
      });
    } else {
      this.loaderService.hide();
      this.loginForm.markAllAsTouched();
    }
  }

  togglePasswordVisibility() {
    this.isHidePassword = !this.isHidePassword;
  }

  checkForDraft(): void {
    const screamDraft = localStorage.getItem('screamDraft');
    if (screamDraft) {
      const parsedDraft = JSON.parse(screamDraft);
      this.dialog.open(CreateAScreamComponent, {
        data: {
          event: Math.floor(parsedDraft.hateRate * 20),
          isSliderSelected: true,
        },
        panelClass: ['scream-dialog-box', 'border-radius'],
        disableClose: true,
      });
    }
  }
}
