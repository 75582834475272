import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfig } from '../../config/app.config';

@Injectable({
  providedIn: 'root',
})
export class JWTTokenVerificationService {
  private api = AppConfig.getAPIURI() + 'jwt';
  constructor(private http: HttpClient) {}

  verifyToken(): Observable<any> {
    return this.http.get(this.api + '/verify', {
      observe: 'response',
    });
  }
}
