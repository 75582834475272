import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { BrandService } from '../../core/service/brandService/brand.service';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { TokenService } from '../../core/service/tokenService/token.service';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scream-field',
  templateUrl: './scream-field.component.html',
  styleUrls: ['./scream-field.component.scss'],
})
export class ScreamFieldComponent implements OnInit, AfterViewInit {
  @ViewChild('editableDiv') editableDiv!: ElementRef;
  @Output() sendBrand = new EventEmitter<any>();
  @Output() sendScream = new EventEmitter<any>();
  @Output() brandRequestClickEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('input', { static: true }) inputField!: ElementRef;
  newContent: any | null;
  currentWord = '';
  isSearching = false;
  brandsList: any;
  isModifyingTag = false;
  selection = window.getSelection();
  currentNodeIndex: any;
  rangeIndex = 0;
  formattedText!: string;
  element = this.inputField?.nativeElement;
  isSpinnerLoading = false;
  activeBrandIndex: number = -1;
  maxCharacterCount = 500;
  responseData: any | null = null;
  platformId: Object;
  blobStorageLink = this.dataTransferService.blobUrl;
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  constructor(
    private brandService: BrandService,
    @Inject(PLATFORM_ID) platformId: Object,
    private router: Router,
    private tokenService: TokenService,
    public readonly dataTransferService: DataTransferService,
  ) {
    this.platformId = platformId;
  }
  ngOnInit(): void {
    this.loadScreamDetailsFromLocalStorage();
    if (!this.router.url.includes('/page-not-found')) this.tagBrandName();
  }

  ngAfterViewInit(): void {
    const dynamicContent = this.newContent;
    if (dynamicContent != null) {
      this.editableDiv.nativeElement.innerHTML = dynamicContent;
      this.setEndOfContentEditable();
      this.tagBrandName();
      setTimeout(() => {
        this.sendScream?.emit(this.formattedText);
      }, 0);
    }
  }

  setEndOfContentEditable() {
    const range = document.createRange();
    const selection = window.getSelection();
    const contentEditable = this.editableDiv.nativeElement;
    range.selectNodeContents(contentEditable);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
    contentEditable.focus();
  }

  tagBrandName() {
    this.responseData = this.brandService.getResponseData();
    if (this.responseData !== null) {
      if (this.responseData && this.responseData.brandName) {
        this.sendBrand.emit(this.responseData);
        this.newContent =
          '<b class="highlighted">@' +
          this.responseData.brandName +
          '</b>\u00A0';

        this.sendScream?.emit('@' + this.responseData.brandName);
      }
    }
  }

  selectEnd() {
    let range, selection;
    range = document.createRange();
    range.selectNodeContents(this.inputField.nativeElement);
    const tempElement = document.createTextNode('\u00A0');
    this.inputField.nativeElement.appendChild(tempElement);
    range.setStart(
      this.inputField.nativeElement.childNodes[this.currentNodeIndex + 2],
      1,
    );
    range.collapse(true);
    selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  }
  loadScreamDetailsFromLocalStorage() {
    const storedScreamDetails = localStorage.getItem('screamDraft');
    if (storedScreamDetails) {
      const parsedScreamDetails = JSON.parse(storedScreamDetails);
      this.newContent = parsedScreamDetails.scream;
    }
  }
  onContentChange(event: any) {
    const description =
      this.inputField.nativeElement.childNodes[this.currentNodeIndex]
        .textContent;
    const ownerTag =
      '<b class="highlighted">@' + event?.brandName + '</b>\u00A0';
    let replacedHTML = description.replace(this.currentWord, ownerTag);
    const tempElement = document.createElement('b');
    tempElement.className = 'highlighted';
    tempElement.innerHTML = replacedHTML;
    let replacedNode = tempElement.childNodes[1];
    let currentNodeDifferenceIndex = 0;
    for (let i = tempElement.childNodes.length - 1; i >= 0; i--) {
      replacedNode = tempElement.childNodes[i];
      if (currentNodeDifferenceIndex == 0) {
        this.inputField.nativeElement.replaceChild(
          replacedNode,
          this.inputField.nativeElement.childNodes[this.currentNodeIndex],
        );
        currentNodeDifferenceIndex++;
      } else {
        currentNodeDifferenceIndex++;
        this.inputField.nativeElement.insertBefore(
          replacedNode,
          this.inputField.nativeElement.childNodes[this.currentNodeIndex],
        );
      }
    }
    this.tokenService.setContent(true);
    this.sendBrand.emit(event);
    this.formattedText = this.inputField.nativeElement.innerText;
    this.sendScream?.emit(this.formattedText);
    this.isSearching = false;
    this.isModifyingTag = false;
    this.selectEnd();
  }
  setCurrentNodeIndex() {
    const childNodes = Array.from(this.inputField.nativeElement.childNodes);
    const index = childNodes.findIndex(
      (node: any) =>
        node === this.selection?.focusNode ||
        node.contains(this.selection?.focusNode),
    );
    this.currentNodeIndex = index;
  }
  handleKeyDown(event: KeyboardEvent): void {
    if (isPlatformBrowser(this.platformId)) {
      this.setCurrentNodeIndex();
      if (
        event.key === 'ArrowDown' ||
        event.key === 'ArrowUp' ||
        event.key === 'Enter'
      ) {
        this.isSearching || this.isModifyingTag ? event.preventDefault() : null;
      }
      if (event.key === 'Backspace' || event.key === 'Delete') {
        this.handleBackSpace(event);
        localStorage.removeItem('screamDraft');
      }
      const range = this.selection?.getRangeAt(0);
      if (
        event.key === ' ' &&
        this.selection?.anchorNode?.parentElement?.textContent &&
        range &&
        this.selection?.anchorNode?.parentElement?.className ===
          'highlighted' &&
        this.selection.anchorNode.parentElement.textContent.length <=
          range.startOffset
      ) {
        if (this.selection?.focusNode?.parentElement?.nextSibling === null) {
          this.inputField.nativeElement.appendChild(
            document.createTextNode('\u00A0'),
          );
          range.setStart(
            this.inputField.nativeElement.childNodes[this.currentNodeIndex + 1],
            1,
          );
          this.inputField.nativeElement.childNodes[
            this.currentNodeIndex + 1
          ].textContent.trim();
        } else {
          if (
            this.selection?.focusNode?.parentNode?.nextSibling instanceof
              Element &&
            this.selection?.focusNode?.parentNode?.nextSibling.className ==
              'highlighted'
          ) {
            this.inputField.nativeElement.insertBefore(
              document.createTextNode('\u00A0'),
              this.inputField.nativeElement.childNodes[this.currentNodeIndex]
                .nextSibling,
            );
          }

          const currentNode =
            this.inputField.nativeElement.childNodes[this.currentNodeIndex];
          if (currentNode.nextSibling instanceof Node) {
            this.inputField.nativeElement.insertBefore(
              document.createTextNode('\u00A0'),
              this.inputField.nativeElement.childNodes[this.currentNodeIndex]
                .nextSibling,
            );
          }
          range?.setStart(
            this.inputField.nativeElement.childNodes[this.currentNodeIndex]
              .nextSibling,
            1,
          );

          range?.collapse(true);
          this.selection = window.getSelection();
          this.selection?.removeAllRanges();
          if (range) {
            this.selection?.addRange(range);
          }
        }
      } else if (
        event.key === ' ' &&
        range &&
        this.selection?.anchorNode?.parentElement?.className ===
          'highlighted' &&
        range?.startOffset === 0
      ) {
        if (
          this.selection?.anchorNode?.parentElement?.previousSibling === null
        ) {
          const tempElement = document.createTextNode('\u00A0');
          this.inputField.nativeElement.insertBefore(
            tempElement,
            this.inputField.nativeElement.childNodes[this.currentNodeIndex],
          );
          range?.setStart(
            this.inputField.nativeElement.childNodes[this.currentNodeIndex]
              .previousSibling,
            0,
          );
          range?.collapse(true);
          this.selection = window.getSelection();
          this.selection?.removeAllRanges();
          range ? this.selection?.addRange(range) : null;
        } else {
          range?.setStart(
            this.inputField.nativeElement.childNodes[this.currentNodeIndex - 1],
            0,
          );
          range?.collapse(true);
          this.selection = window.getSelection();
          this.selection?.removeAllRanges();
          range ? this.selection?.addRange(range) : null;
        }
      }
    }
  }
  tag() {
    this.setCurrentNodeIndex();
    if (
      this.selection?.anchorNode?.parentElement?.outerHTML &&
      this.selection?.anchorNode?.parentElement?.className === 'highlighted'
    ) {
      this.isModifyingTag = true;
    }
    if (
      this.getSubstringFromArray()?.trim()[0] == '@' &&
      this.currentWord.length > 1 &&
      this.currentNodeIndex !== -1
    ) {
      this.onSearch(
        this.currentWord.substring(1, this.currentWord.length).trim(),
      );
      this.formattedText = this.inputField.nativeElement.innerText;
      this.sendScream.emit(this.formattedText);
      return (this.isSearching = true);
    }
    this.formattedText = this.inputField.nativeElement.innerText;
    this.sendScream.emit(this.formattedText);
    return (this.isSearching = false);
  }

  onSearch(searchTerm: string) {
    this.brandsList = [];
    this.isSpinnerLoading = true;
    if (searchTerm.length === 0) {
      this.isSpinnerLoading = false;
      return;
    }
    this.brandService.searchBrand(searchTerm).subscribe({
      next: (response) => {
        if (
          response.body.responseBody.length >= 0 &&
          response.body.responseCode == 200
        ) {
          this.selectFirstItem();
          this.brandsList = response.body.responseBody.slice(0, 10);
          const verification = [];

          for (const brand of response.body.responseBody) {
            verification.push(brand.documentVerificationStatus);
          }

          this.isSpinnerLoading = false;
        } else {
          this.isSpinnerLoading = false;
        }
      },
      error: () => {
        this.isSpinnerLoading = false;
      },
    });
  }

  onKeyUp(event: KeyboardEvent) {
    if (
      event.key === 'ArrowDown' ||
      event.key === 'ArrowUp' ||
      event.key === 'Enter'
    ) {
      event.preventDefault();
      if (event.key === 'ArrowDown') {
        this.selectNextItem();
      } else if (event.key === 'ArrowUp') {
        this.selectPreviousItem();
      } else if (event.key === 'Enter') {
        this.handleEnterKey(event);
      }
    }
  }

  selectFirstItem() {
    this.activeBrandIndex = 0;
  }

  selectPreviousItem() {
    if (this.activeBrandIndex > 0) {
      this.activeBrandIndex--;
    }
  }

  selectNextItem() {
    if (this.activeBrandIndex < this.brandsList.length - 1) {
      this.activeBrandIndex++;
    }
  }

  handleEnterKey(event: KeyboardEvent) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isSearching || this.isModifyingTag) {
        if (this.brandsList?.length === 0) {
          event.preventDefault();

          const selection = window.getSelection();
          if (selection) {
            const range = selection.getRangeAt(0);
            const newline = document.createElement('div');
            newline.innerHTML = '<br>';
            range.insertNode(newline);
            range.setStartAfter(newline);
            range.setEndAfter(newline);
            selection.removeAllRanges();
            selection.addRange(range);
          }
        } else {
          this.onContentChange(this.brandsList[this.activeBrandIndex]);
        }
        this.selectFirstItem();

        this.isSearching = false;
        this.isModifyingTag = false;
      }
    }
  }

  navigateToBrandCreation() {
    this.brandRequestClickEvent.emit();
  }

  getSubstringFromArray(): string {
    if (
      this.selection &&
      this.selection.focusNode &&
      this.selection.focusNode.textContent
    ) {
      const range = this.selection?.getRangeAt(0);
      const charsArray = this.selection?.focusNode?.textContent?.split('');
      let startAndEnd = this.setStartAndEnd(
        range?.startOffset,
        range?.startOffset,
        charsArray,
      );
      const substring = charsArray
        .slice(startAndEnd[0] + 1, startAndEnd[1])
        .join('');
      this.currentWord = substring?.trim();
      return substring;
    }
    return '';
  }

  setStartAndEnd(start: any, end: any, charsArray: any): any {
    while (start >= 0 && charsArray[start] !== ' ') {
      start--;
    }
    while (end < charsArray.length && charsArray[end] !== ' ') {
      end++;
    }
    return start === end
      ? this.setStartAndEnd(start - 1, end, charsArray)
      : [start, end];
  }

  handleBackSpace(event: any) {
    if (
      event.key === 'Backspace' &&
      this.inputField?.nativeElement?.childNodes[this.currentNodeIndex]
        ?.className === 'highlighted' &&
      this.inputField?.nativeElement?.childNodes[
        this.currentNodeIndex
      ]?.textContent?.trim()?.length > 0
    ) {
      this.inputField?.nativeElement?.replaceChild(
        document.createTextNode('\u00A0'),
        this.inputField?.nativeElement?.childNodes[this.currentNodeIndex],
      );
      const range = this.selection?.getRangeAt(0);
      range?.setStart(
        this.inputField?.nativeElement?.childNodes[this.currentNodeIndex],
        1,
      );
      this.isSearching = false;
      this.isModifyingTag = false;
    } else {
      const range = this.selection?.getRangeAt(0);
      if (
        event.key === 'Delete' &&
        this.inputField?.nativeElement?.childNodes[this.currentNodeIndex + 1]
          ?.className === 'highlighted' &&
        this.inputField?.nativeElement?.childNodes[
          this.currentNodeIndex
        ]?.textContent?.trim()?.length > 0 &&
        range &&
        this.inputField?.nativeElement?.childNodes[this.currentNodeIndex]
          ?.textContent?.length <= range.startOffset
      ) {
        this.inputField?.nativeElement?.replaceChild(
          document.createTextNode('\u00A0'),
          this.inputField?.nativeElement?.childNodes[this.currentNodeIndex]
            .nextSibling,
        );
        range?.setStart(
          this.inputField?.nativeElement?.childNodes[this.currentNodeIndex]
            .nextSibling,
          1,
        );
        this.isSearching = false;
        this.isModifyingTag = false;
      } else if (
        event.key === 'Delete' &&
        this.inputField?.nativeElement?.childNodes[this.currentNodeIndex]
          ?.className === 'highlighted' &&
        this.inputField?.nativeElement?.childNodes[
          this.currentNodeIndex
        ]?.textContent?.trim()?.length > 0
      ) {
        this.inputField?.nativeElement?.replaceChild(
          document.createTextNode('\u00A0'),
          this.inputField?.nativeElement?.childNodes[this.currentNodeIndex],
        );

        range?.setStart(
          this.inputField?.nativeElement?.childNodes[this.currentNodeIndex],
          1,
        );
        this.isSearching = false;
        this.isModifyingTag = false;
      }
    }
  }
}
