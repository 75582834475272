import {
  MatBottomSheet,
  MatBottomSheetConfig,
} from '@angular/material/bottom-sheet';
import { DataTransferService } from './../../../../core/service/data-transfer/data-transfer.service';
import { ScreamService } from './../../../../core/service/screamService/scream.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShareComponent } from '../../../../shared/share/share.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input() scream: any;
  @Input() userProfile: any;
  suid!: any;
  isScreamUp = false;
  screamUpCount = 0;
  errorMessage: any;

  constructor(
    private readonly screamService: ScreamService,
    public router: Router,
    public dataTransferService: DataTransferService,
    private _bottomSheet: MatBottomSheet,
  ) {}
  ngOnInit(): void {
    this.fetchScreamUps();
  }

  fetchScreamUps() {
    this.screamUpCount = this.scream?.screamUpsCount;
    this.isScreamUp = this.scream?.screamUp;
  }

  screamUp() {
    if (
      this.dataTransferService.isLoggedin() ||
      this.dataTransferService.isBusinessLoggedin()
    ) {
      this.screamUpCount = this.isScreamUp
        ? this.screamUpCount - 1
        : this.screamUpCount + 1;
      this.screamService
        .screamUp(this.scream.suid)
        .then((response: any) => {
          this.screamUpCount =
            response.responseCode == 200
              ? this.screamUpCount
              : this.screamUpCount;
          this.dataTransferService.updateScreamUpCount(this.screamUpCount);
        })
        .catch((response) => {
          this.errorMessage = response;
        });
      this.isScreamUp = !this.isScreamUp;
    } else {
      this.router.navigate(['/login']);
    }
  }

  goToResponses() {
    if (!this.dataTransferService.isLoggedin()) {
      return this.router.navigate(['/login']);
    }
    this.suid = this.scream.suid;
    this.router.navigate(['/scream/'], {
      queryParams: {
        suid: this.suid,
      },
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  openShare() {
    if (!this.dataTransferService.isLoggedin()) {
      return this.router.navigate(['/login']);
    }
    const config: MatBottomSheetConfig = {
      data: { suid: this.scream.suid },
      panelClass: ['custom-bottom-sheet'],
    };
    this._bottomSheet.open(ShareComponent, config);
  }
}
