import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { BottomNavBarService } from './../../core/service/bottomNavBar/bottom-nav-bar.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GetAppService } from '../../core/service/getAppService/get-app.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-bottom-nav-bar',
  templateUrl: './bottom-nav-bar.component.html',
  styleUrls: ['./bottom-nav-bar.component.scss'],
})
export class BottomNavBarComponent {
  constructor(
    public bottomService: BottomNavBarService,
    public router: Router,
    private service: DataTransferService,
    public dialog: MatDialog,
    private getAppService: GetAppService,
    public location: Location,
  ) {}
  openScreamDialog() {
    this.router.navigate([], {
      queryParams: { scream_it: 'true' },
      queryParamsHandling: 'merge',
    });
  }
  goToScream() {
    this.router.navigate(['scream/scream-it']);
  }
  loggedIn(): boolean {
    return this.service.isLoggedin();
  }
  isBusinessLoggedIn(): boolean {
    return this.service.isBusinessLoggedin();
  }
  login() {
    return this.router.navigate(['/login']);
  }
  signUp() {
    return this.router.navigate(['/sign-up']);
  }
  openPlayStoreOrAppStore() {
    this.getAppService.getApp();
  }
}
