import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/app.config';
import { isPlatformServer } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  private api = AppConfig.getAPIURI() + 'brand';
  private restAPI = AppConfig.getRestaurantAPIURI();
  private isRequesting: boolean = false;
  private selectedBrand: { brandName: string; websiteUrl: string } = {
    brandName: '',
    websiteUrl: '',
  };

  selectedBranch!: any;
  statisticsTimePeriod!: string;
  jwt: any;
  platformId: Object;
  responseData: any;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: Object,

    public cookieService: SsrCookieService,
  ) {
    this.platformId = platformId;
  }

  getSelectedBranch() {
    return this.selectedBranch;
  }

  setSelectedBranch(branch: any) {
    return (this.selectedBranch = branch);
  }

  getStatisticsPeriod() {
    return this.statisticsTimePeriod;
  }

  setStatisticsPeriod(period: string) {
    return (this.statisticsTimePeriod = period);
  }

  fetchBrand(): Observable<any> {
    return this.http.get(this.api + '/fetch-brand', {
      observe: 'response',
    });
  }

  fetchCategories(): Observable<any> {
    return this.http.get(this.restAPI + '/category', {
      observe: 'response',
    });
  }

  searchBrand(input: string): Observable<any> {
    return this.http.get(this.api + '/search-brand/' + input, {
      observe: 'response',
    });
  }

  searchNotVerifiedBrand(brandName: string): Observable<any> {
    return this.http.get(
      this.api + '/search-not-verified-brands/' + brandName,
      {
        observe: 'response',
      },
    );
  }

  fetchBrandProfile(brandName: any, request: any): Observable<any> {
    const params = request;
    return this.http.get(this.api + `/fetch-brand/${brandName}`, {
      params,
      observe: 'response',
    });
  }

  fetchBrandProfileForMenu(brandName: any): Observable<any> {
    return this.http.get(this.api + `/${brandName}/menu`, {
      observe: 'response',
    });
  }

  fetchBusinessScreams(brandName: any, request: any): Observable<any> {
    const params = request;
    return this.http.get(this.api + `/fetch-business-screams/${brandName}`, {
      params,
      observe: 'response',
    });
  }

  fetchBrandInsights(
    brandName: any,
    request: any,
    isPositive: boolean,
  ): Observable<any> {
    const params = { request, isPositive };
    return this.http.get(this.api + `/fetch-insights/${brandName}`, {
      params,
      observe: 'response',
    });
  }

  followOrUnfollowBrand(followee: any): Promise<any> {
    if (this.isRequesting) {
      return Promise.resolve();
    }
    this.isRequesting = true;
    return this.http
      .post(this.api + `/followOrUnfollow/${followee}`, {
        observe: 'response',
      })
      .toPromise()
      .finally(() => {
        this.isRequesting = false;
      });
  }
  fetchingBrandFollowers(followee: any, params: any): Observable<any> {
    return this.http.get(this.api + `/fetch-brand-followers/${followee}`, {
      params,
      observe: 'response',
    });
  }

  fetchBrandDetails(): Observable<any> {
    return this.http.get(this.api + '/details', {
      observe: 'response',
    });
  }

  updateBrand(brand: any): Observable<any> {
    return this.http.put(this.api + '/update-brand-details', brand, {
      observe: 'response',
    });
  }
  businessCreation(brand: any): Observable<any> {
    return this.http.post(this.api + '/save-business-account', brand, {
      observe: 'response',
    });
  }

  addCategory(categoryName: any): Observable<any> {
    return this.http.post(this.restAPI + '/category/create', categoryName, {
      observe: 'response',
    });
  }

  addItem(foodItemDTO: any): Observable<any> {
    return this.http.post(this.restAPI + '/item/create', foodItemDTO, {
      observe: 'response',
    });
  }
  businessLogin(brand: any): Observable<any> {
    return this.http.post(this.api + '/login', brand, {
      observe: 'response',
    });
  }

  setSelectedBrand(brand: { brandName: string; websiteUrl: string }) {
    this.selectedBrand = brand;
  }

  getSelectedBrand() {
    return this.selectedBrand;
  }
  businessForgotPasswordOtp(brand: any): Observable<any> {
    let body = brand;
    return this.http.post(this.api + '/forgot-password', body, {
      observe: 'response',
    });
  }
  sendBusinessOtp(otp: any, token: any, logData: any): Observable<any> {
    let logDTO = {
      operatingSystem: logData?.logDTO?.operatingSystem,
      deviceType: logData?.logDTO?.deviceType,
    };

    let otpDTO = {
      code: otp,
      logDTO,
    };
    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.post(
      AppConfig.getAPIURI() + 'otp/checkBusinessOtp',
      otpDTO,
      {
        observe: 'response',
        headers: headers,
      },
    );
  }
  sendBusinessOtpWithoutHeader(otp: any, logData: any): Observable<any> {
    let logDTO = {
      operatingSystem: logData?.logDTO?.operatingSystem,
      deviceType: logData?.logDTO?.deviceType,
    };

    let otpDTO = {
      code: otp,
      logDTO,
    };
    return this.http.post(
      AppConfig.getAPIURI() + 'otp/checkBusinessOtp',
      otpDTO,
      {
        observe: 'response',
      },
    );
  }
  resendOtp(token: any, data: any): Observable<any> {
    let body = { purpose: data };
    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.post(AppConfig.getAPIURI() + 'otp/business/resend', body, {
      observe: 'response',
      headers: headers,
    });
  }
  resendOtpWithoutHeader(token: any, data: any): Observable<any> {
    let body = { purpose: data };
    return this.http.post(AppConfig.getAPIURI() + 'otp/business/resend', body, {
      observe: 'response',
    });
  }
  newPasswordForBusiness(
    password: any,
    token: any,
    logData: any,
  ): Observable<any> {
    let logDTO = {
      operatingSystem: logData?.logDTO?.operatingSystem,
      deviceType: logData?.logDTO?.deviceType,
      loginTime: logData?.logDTO?.loginTime,
    };

    let body = {
      password: password.password,
      logDTO,
    };
    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.put(this.api + '/reset-password', body, {
      observe: 'response',
      headers: headers,
    });
  }

  getDetailsByPincode(pincode: any): Observable<any> {
    return this.http.get(this.api + '/fetch-state-and-city/' + pincode, {
      observe: 'response',
    });
  }

  postBusinessVerification(businessVerificationDetails: any): Observable<any> {
    return this.http.post(
      this.api + '/business-verification',
      businessVerificationDetails,
      {
        observe: 'response',
      },
    );
  }
  checkBrandName(brandName: any): Observable<any> {
    return this.http.post(this.api + `/${brandName}`, {
      observe: 'response',
    });
  }
  fetchVerificationStatus() {
    return this.http.get(this.api + '/fetch-business-verification-status', {
      observe: 'response',
    });
  }
  isBusinessLoggedin() {
    if (isPlatformServer(this.platformId)) {
      this.jwt = this.cookieService.get('BusinessToken');
    } else {
      this.jwt = localStorage.getItem('BusinessToken');
    }
    if (this.jwt) {
      return true;
    } else {
      return false;
    }
  }

  setBrandDetails(brandDetails: any) {
    localStorage.setItem('BrandDetails', brandDetails);
  }

  getBrandDetails(): any {
    if (isPlatformServer(this.platformId)) {
      return '{}';
    } else {
      return localStorage.getItem('BrandDetails') || '{}';
    }
  }
  getBusinessName() {
    const brandDetailsString = this.getBrandDetails();
    if (brandDetailsString) {
      const brandDetails = JSON.parse(brandDetailsString);
      return brandDetails.brandName;
    }
  }
  getCategoryName() {
    const brandDetailsString = this.getBrandDetails();
    if (brandDetailsString) {
      const brandDetails = JSON.parse(brandDetailsString);
      return brandDetails.categoryName;
    }
  }
  getFollowersCount() {
    const brandDetailsString = this.getBrandDetails();
    if (brandDetailsString) {
      const brandDetails = JSON.parse(brandDetailsString);
      return brandDetails.followersCount;
    }
  }
  getAssuranceScore() {
    const brandDetailsString = this.getBrandDetails();
    if (brandDetailsString) {
      const brandDetails = JSON.parse(brandDetailsString);
      return brandDetails.assuranceScore;
    }
  }
  logoutSession(sessionId: any) {
    let body = {
      sessionId: sessionId,
    };
    return this.http.post(this.api + `/logout`, body, {
      observe: 'response',
    });
  }
  setResponseData(responseData: any) {
    this.responseData = responseData;
  }
  getResponseData() {
    return this.responseData;
  }
  fetchBranches() {
    return this.http.get(this.api + '/fetch-branch-names', {
      observe: 'response',
    });
  }

  fetchBranchReviewRatings(
    selectedBranch: any,
    pageIndex: any,
    pageSize: any,
  ): Observable<any> {
    const params = new HttpParams()
      .set('branch', selectedBranch)
      .set('pageIndex', pageIndex)
      .set('pageSize', pageSize);
    return this.http.get(this.api + `/fetch-branch-ratings`, {
      params,
      observe: 'response',
    });
  }

  fetchBrandBranchInsights(
    selectedBranch: string,
    pageIndex: any,
    pageSize: any,
    isPositive: boolean,
  ): Observable<any> {
    let params = new HttpParams();
    params = params
      .set('branchName', selectedBranch)
      .set('pageIndex', pageIndex)
      .set('pagesize', pageSize)
      .set('isPositive', isPositive);
    return this.http.get(this.api + `/fetch-branch-insights`, {
      params,
      observe: 'response',
    });
  }

  fetchBranchScreams(
    selectedBranch: any,
    pageIndex: any,
    pageSize: any,
  ): Observable<any> {
    const params = new HttpParams()
      .set('branch', selectedBranch)
      .set('pageIndex', pageIndex)
      .set('pageSize', pageSize);
    return this.http.get(this.api + `/fetch-branch-screams`, {
      params,
      observe: 'response',
    });
  }

  getScreamStatistics(params: any): Observable<any> {
    return this.http.get(this.api + '/scream-statistics', {
      params: params,
      observe: 'response',
    });
  }

  getLiveStatus(businessName: any) {
    const url = `${this.api}/${businessName}/menu`;
    return this.http.get(url, {
      observe: 'response',
    });
  }

  changeLiveStatus() {
    const baseUrl = this.restAPI + 'menu';
    const url = `${baseUrl}/status/update`;
    return this.http.put(url, {
      observe: 'response',
    });
  }

  postCategory(categoryName: any): Observable<any> {
    const apiUrl = `${this.restAPI}category/create?categoryName=${categoryName}`;
    const params = { categoryName: categoryName };
    return this.http.post(apiUrl, params);
  }

  getBusinessCategories() {
    const apiUrl = this.restAPI + 'category';
    return this.http.get(apiUrl, {
      observe: 'response',
    });
  }

  deleteCategory(categoryUuid: any): Observable<any> {
    const baseUrl = this.restAPI + 'category';
    const url = `${baseUrl}/delete/${categoryUuid}`;
    return this.http.delete(url, {
      observe: 'response',
    });
  }

  changeCategoryAvail(categoryUuid: any): Observable<any> {
    const baseUrl = this.restAPI + 'category';
    const url = `${baseUrl}/status/update/${categoryUuid}`;
    return this.http.put(url, {
      observe: 'response',
    });
  }

  changeItemAvail(itemUuid: any): Observable<any> {
    const baseUrl = this.restAPI + 'item';
    const url = `${baseUrl}/status/update/${itemUuid}`;
    return this.http.put(url, {
      observe: 'response',
    });
  }

  fetchAddedItems(brandName: any): Observable<any> {
    const params = new HttpParams().set('brandName', brandName);
    return this.http.get(this.restAPI + 'item', {
      params,
      observe: 'response',
    });
  }

  deleteItem(itemUuid: any): Observable<any> {
    const url = `${this.restAPI}delete/${itemUuid}`;
    return this.http.delete(url, {
      observe: 'response',
    });
  }

  fetchBrandRatings(brandName: any, request: any): Observable<any> {
    const params = request;
    return this.http.get(this.api + `/fetch-ratings/${brandName}`, {
      params,
      observe: 'response',
    });
  }
}
