import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from './../../core/service/userService/user.service';
import { FollowersComponent } from '../followers/followers.component';
import { TokenService } from './../../core/service/tokenService/token.service';
import { LogoutComponent } from '../logout/logout.component';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { BrandService } from './../../core/service/brandService/brand.service';
import {
  MatBottomSheet,
  MatBottomSheetConfig,
} from '@angular/material/bottom-sheet';
import { ShareComponent } from '../share/share.component';
import { BurgerMenuComponent } from '../burger-menu/burger-menu.component';
import { isPlatformBrowser, isPlatformServer, Location } from '@angular/common';
import { TooltipPosition } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { SnackbarService } from '../../core/service/snackBar/snackbar.service';
import { ReportComponent } from '../report/report.component';
import { LoaderService } from './../../core/service/loader/loader.service';
import { BlockUserComponent } from '../block-user/block-user.component';
import { SearchBarService } from './../../core/service/search/search-bar.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  error: any;
  userProfileImage: any;
  profileImage: any;
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  @Input() isScreamsVisible!: boolean;
  @Input() isButtonVisible!: boolean;
  @Input() profile: any;
  @Input() followed!: boolean;
  @Input() user: any;
  @Input() userName!: string;
  @Input() image: any;
  @Input() brand!: boolean;
  @Input() followersCount: any;
  @Input() followingCount: any;
  @Input() screamsCount: any;
  successMessage: any;
  platformId: Object;
  isCardOpen: boolean = false;
  suid: any;
  isCardOpens: boolean = false;
  tooltipPosition: TooltipPosition = 'after';
  verification: any;

  constructor(
    public router: Router,
    public userService: UserService,
    private brandService: BrandService,
    public dialog: MatDialog,
    public tokenService: TokenService,
    public readonly dataTransferService: DataTransferService,
    private _bottomSheet: MatBottomSheet,
    private titleService: Title,
    private metaService: Meta,
    private snackBarService: SnackbarService,
    private loaderService: LoaderService,
    private location: Location,
    private elementRef: ElementRef,
    private searchbarService: SearchBarService,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.platformId = platformId;
  }

  ngOnInit(): void {
    this.suid = this.profile?.suid;
    const currentPage = this.router.url;
    if (!currentPage?.includes('/brand')) {
      const userName = this.profile?.fullName;
      const fullName = this.profile?.userName;
      this.setUserProfileTitle(userName, fullName);
    }

    if (!currentPage?.includes('/brand')) {
      this.dataTransferService.followingCount$.subscribe((count) => {
        this.followingCount = count;
      });
    }
    this.dataTransferService.screamDeleted.subscribe(() => {
      this.screamsCount--;
    });
    this.userProfileImage = this.dataTransferService.getEditProfileImage();
    this.profileImage = JSON.parse(
      this.dataTransferService.getUserDetails() || '',
    );
    this.verification = this.tokenService.getContent();
  }

  login() {
    if (!this.dataTransferService.isLoggedin()) {
      return this.router.navigate(['/login']);
    }
  }

  setUserProfileTitle(userName: string, fullName: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.titleService.setTitle(` ${userName} - Scremer`);
    } else if (isPlatformServer(this.platformId)) {
      this.metaService.updateTag({ name: 'title', content: userName });
    }
    this.titleService.setTitle(` ${userName} (@${fullName}) - Scremer`);
  }

  openShare(profile: any) {
    this.login();
    const config: MatBottomSheetConfig = {
      data: { profile: profile },
      panelClass: ['custom-bottom-sheet'],
    };
    this._bottomSheet.open(ShareComponent, config);
  }

  goToEditProfile() {
    this.router.navigate(['/edit-profile']);
  }

  copyLink() {
    if (isPlatformBrowser(this.platformId)) {
      let url = window.location.origin;
      if (this.suid !== null && this.suid !== undefined) {
        url += '/scream?suid=' + this.profile.suid;
      } else if (
        this.profile.brandName !== null &&
        this.profile.brandName !== undefined
      ) {
        url += '/brand/' + this.profile?.brandName;
      } else if (
        this.profile.userName !== null &&
        this.profile.userName !== undefined
      ) {
        url += '/' + this.profile?.userName;
      }
      const input = document.createElement('input');
      input.setAttribute('value', url);
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      this.snackBarService.openSnackBar('Link Copied', 'success-snackbar');
    }
  }

  openDialogFollowers(selectedTab: string): void {
    if (this.dataTransferService.isLoggedin()) {
      const dialogRef = this.dialog.open(FollowersComponent, {
        data: { selectedTab, user: this.user, brand: false },
        panelClass: ['dialog-box'],
      });
    } else {
      this.login();
    }
  }

  setProfileImage() {
    if (isPlatformBrowser(this.platformId)) {
      return this.profile?.profileImage !== null
        ? this.blobStorageLink + this.userProfileImage
        : this.defaultImage;
    }
  }

  openCard() {
    this.login();
    if (this.isCardOpen) {
      this.isCardOpen = false;
    } else {
      this.isCardOpen = true;
    }
  }

  openCards() {
    if (this.isCardOpens) {
      this.isCardOpens = false;
    } else {
      this.isCardOpens = true;
    }
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isCardOpen = false;
      this.isCardOpens = false;
    }
  }

  openReportUser() {
    this.tokenService.setContent(false);
    const dialogRef = this.dialog.open(ReportComponent, {
      width: '558px',
      panelClass: ['report'],
      autoFocus: true,
      data: { user: this.user, profile: this.profile },
    });
    document.body.classList.add('cdk-global-scrollblock');
    dialogRef.afterClosed().subscribe(() => {
      document.body.classList.remove('cdk-global-scrollblock');
    });
  }

  blockUser() {
    const dialogRef = this.dialog.open(BlockUserComponent, {
      width: '348px',
      panelClass: ['blockuser'],
      autoFocus: true,
      data: { profile: this.profile },
    });
    if (this.dataTransferService.isLoggedin()) {
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'block') {
          this.loaderService.show();
          this.userService.blockUser(this.user).subscribe({
            next: (res: any) => {
              this.loaderService.hide();
              if (res.body.responseCode === 200) {
                this.snackBarService.openSnackBar(
                  'User blocked successfully',
                  'success-snackbar',
                );
                this.location.back();
                this.searchbarService.blockUser(this.profile?.userName);
              } else {
                this.snackBarService.openSnackBar(
                  'Failed to block user',
                  'error-snackbar',
                );
              }
            },
            error: (res) => {
              this.loaderService.hide();
              this.snackBarService.openSnackBar(
                res.error.responseMessage,
                'error-snackbar',
              );
            },
          });
        }
      });
    } else {
      this.login();
    }
  }

  follow() {
    if (this.dataTransferService.isLoggedin()) {
      if (this.followed) {
        const dialogRef = this.dialog.open(LogoutComponent, {
          width: '300px',
          panelClass: ['border-radius'],
          autoFocus: false,
        });
        this.tokenService.setContent(true);
        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'unfollow') {
            this.followed = false;
            this.userService
              .followOrUnfollowUser(this.user)
              .then((data: any) => {
                if (data.responseCode === 201) {
                  this.successMessage = data.responseMessage;
                  this.followersCount--;
                }
              })
              .catch((data) => {
                this.error = data.error?.responseMessage;
              });
          }
        });
      } else {
        this.followed = true;
        this.userService
          .followOrUnfollowUser(this.user)
          .then((data: any) => {
            if (data.responseCode === 200) {
              this.successMessage = data.responseMessage;
              this.followersCount++;
            }
          })
          .catch((data) => {
            this.error = data.error?.responseMessage;
            this.followed = false;
          });
      }
    } else {
      this.login();
    }
  }

  followBrand() {
    if (this.dataTransferService.isLoggedin()) {
      if (this.followed) {
        const dialogRef = this.dialog.open(LogoutComponent, {
          width: '300px',
          panelClass: ['border-radius'],
          autoFocus: false,
        });
        this.tokenService.setContent(true);
        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'unfollow') {
            this.followed = false;
            this.brandService
              .followOrUnfollowBrand(this.profile?.ubid)
              .then((data: any) => {
                if (data.responseCode === 201) {
                  this.successMessage = data.responseMessage;
                }
                this.followersCount--;
              })
              .catch((error: any) => {
                this.error = error.error?.responseMessage;
              });
          }
        });
      } else {
        this.followed = true;
        this.brandService
          .followOrUnfollowBrand(this.profile?.ubid)
          .then((data: any) => {
            if (data.responseCode === 200) {
              this.successMessage = data.responseMessage;
            }
            this.followersCount++;
          })
          .catch((error: any) => {
            this.error = error.error?.responseMessage;
            this.followed = false;
          });
      }
    } else {
      this.login();
    }
  }

  openBottomSheet(profile: any, user: any) {
    if (this.dataTransferService.isLoggedin()) {
      this.tokenService.setContent(false);
      const config: MatBottomSheetConfig = {
        data: { profile: profile, user: user },
        panelClass: ['custom-bottom-sheet'],
      };
      this._bottomSheet.open(BurgerMenuComponent, config);
    } else {
      this.login();
    }
  }

  openBrandFollowers(selectedTab: string) {
    if (this.dataTransferService.isLoggedin()) {
      const dialogRef = this.dialog.open(FollowersComponent, {
        data: { selectedTab, brandName: this.profile?.brandName, brand: true },
        panelClass: ['dialog-box'],
      });
    } else {
      this.login();
    }
  }
}
