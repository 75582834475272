import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfig } from '../../config/app.config';
import { DataTransferService } from '../data-transfer/data-transfer.service';
import { HeaderService } from '../header/header.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  updateAddressSubject = new BehaviorSubject([]);
  updateAddressObserver = this.updateAddressSubject.asObservable();
  private isRequesting: boolean = false;
  private api = AppConfig.getAPIURI() + 'user';
  private reportAPI = AppConfig.getAPIURI() + 'report';
  userImage: any;
  constructor(
    private http: HttpClient,
    private headerService: HeaderService,
    private dataTransferService: DataTransferService,
  ) {}
  registerUser(user: any): Observable<any> {
    return this.http.post(this.api + '/sign-up', user, {
      observe: 'response',
    });
  }

  blockUser(accountToBlockUuid: string): Observable<any> {
    const params = { accountToBlockUuid: accountToBlockUuid };
    return this.http.put(this.api + '/block', accountToBlockUuid, {
      params: params,
      observe: 'response',
    });
  }

  checkUserName(user: any): Observable<any> {
    return this.http.post(this.api + '/check-user-name', user, {
      observe: 'response',
    });
  }
  loginUser(user: any): Observable<any> {
    let body = user;
    return this.http.post(this.api + '/login', body, {
      observe: 'response',
    });
  }
  deleteUser(user: any): Observable<any> {
    let body = user;
    return this.http.delete(this.api + '/delete-user', {
      body: body,
      observe: 'response',
    });
  }
  getForgotPasswordOtp(user: any): Observable<any> {
    let body = user;
    return this.http.post(this.api + '/forgot-password', body, {
      observe: 'response',
    });
  }

  updateUser(user: any): Observable<any> {
    return this.http.put(this.api + '/update-user-details', user, {
      observe: 'response',
    });
  }
  fetchUserDetails(): Observable<any> {
    return this.http.get(this.api + '/fetch-userDetails', {
      observe: 'response',
    });
  }
  newPassword(password: any, token: any, logData: any): Observable<any> {
    let logDTO = {
      operatingSystem: logData?.logDTO?.operatingSystem,
      deviceType: logData?.logDTO?.deviceType,
      loginTime: logData?.logDTO?.loginTime,
    };

    let body = {
      password: password.password,
      logDTO,
    };
    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.put(this.api + '/reset-password', body, {
      observe: 'response',
      headers: headers,
    });
  }

  changePassword(data: any): Observable<any> {
    return this.http.post(this.api + '/change-password', data, {
      observe: 'response',
    });
  }
  postAddress(address: any): Observable<any> {
    return this.http.post(this.api + '/save-address', address, {
      observe: 'response',
    });
  }
  fetchUserAddress(): Observable<any> {
    return this.http.get(this.api + '/fetch-address', {
      observe: 'response',
    });
  }

  followOrUnfollowUser(followee: any): Promise<any> {
    if (this.isRequesting) {
      return Promise.resolve();
    }
    this.isRequesting = true;
    return this.http
      .post(this.api + `/followOrUnfollow/${followee}`, {
        observe: 'response',
      })
      .toPromise()
      .finally(() => {
        this.isRequesting = false;
      });
  }
  fetchingMyFollowers(params: any): Observable<any> {
    return this.http.get(this.api + '/fetch-my-followers', {
      params,
      observe: 'response',
    });
  }
  fetchingMyFollowing(params: any): Observable<any> {
    return this.http.get(this.api + '/fetch-my-following', {
      params,
      observe: 'response',
    });
  }
  fetchingUserFollowers(followee: any, params: any): Observable<any> {
    return this.http.get(this.api + `/fetch-followers/${followee}`, {
      params,
      observe: 'response',
    });
  }
  fetchingUserFollowing(followee: any, params: any): Observable<any> {
    return this.http.get(this.api + `/fetch-following/${followee}`, {
      params,
      observe: 'response',
    });
  }
  reportUser(data: any): Observable<any> {
    return this.http.post(this.reportAPI + '/user', data, {
      observe: 'response',
    });
  }
  fetchReferral(): Observable<any> {
    return this.http.get(this.api + '/fetch-referral', {
      observe: 'response',
    });
  }
  checkingReferralCode(referralCode: any): Observable<any> {
    return this.http.post(this.api + `/referralCode/${referralCode}`, {
      observe: 'response',
    });
  }
  updateUserAddress(user: any): Observable<any> {
    return this.http.put(this.api + '/edit-delete-address', user, {
      observe: 'response',
    });
  }
  updateAddress(address: any) {
    this.updateAddressSubject.next(address);
  }
  logoutSession(sessionId: any) {
    let body = {
      sessionId: sessionId,
    };
    return this.http.post(this.api + `/logout`, body, {
      observe: 'response',
    });
  }
  setEditProfileImage(imageUrl: any) {
    const userDetailsString = this.dataTransferService.getUserDetails();
    if (userDetailsString) {
      const userDetails = JSON.parse(userDetailsString);
      this.userImage = imageUrl;
      userDetails.profileImage = imageUrl;
      this.dataTransferService.setUserDetails(JSON.stringify(userDetails));
      this.headerService.updateImage(imageUrl);
    }
  }

  unBlockUserAccount(uuid: string): Observable<any> {
    const params = { accountToUnBlockUuid: uuid };
    return this.http.put(
      this.api + '/unblock',
      {},
      {
        params: params,
        observe: 'response',
      },
    );
  }

  fetchBlockedUserAccounts(params: any): Observable<any> {
    return this.http.get(this.api + '/blocked', {
      params: params,
      observe: 'response',
    });
  }

  fetchMobileNumber(): Observable<any> {
    return this.http.get(this.api + '/get-mobile-number', {
      observe: 'response',
    });
  }
}
