<div>
  <div
    class="pt-7 padding-top-56 d-web-none pb-3"
    *ngIf="!loggedIn() && router.url.startsWith('/home')"
  >
    <div
      class="button-log background-white-color w-100 d-flex justify-content-between display-desktops-only p-3 max-div"
    >
      <div class="me-3 w-100 d-mobile-none">
        <button
          mat-raised-button
          (click)="signUp()"
          class="btn btn-primary background-color fw-700 fs-16 lato-font-family py-2 white-color px-4 bottom-padding w-100"
        >
          <div class="px-4">Sign up</div>
        </button>
      </div>
      <div class="w-100">
        <span>
          <button
            (click)="login()"
            mat-raised-button
            class="btn button fw-700 lato-font-family py-2 fs-16 bottom-padding w-100"
          >
            <div class="active-link px-4">Log in</div>
          </button>
        </span>
      </div>
      <div class="ms-3 w-100 d-tab-none">
        <button
          mat-raised-button
          (click)="openPlayStoreOrAppStore()"
          class="btn btn-primary background-color fw-700 fs-16 lato-font-family py-2 white-color px-4 bottom-padding w-100"
        >
          <div class="px-4">Get app</div>
        </button>
      </div>
    </div>
  </div>
  <div class="pt-7 padding-top-56">
    <app-profile-and-hate-rate-slider></app-profile-and-hate-rate-slider>
    <app-home-shimmer *ngIf="homeShimmer"></app-home-shimmer>
  </div>
</div>
<div [infiniteScrollDistance]="2" infiniteScroll (scrolled)="onScroll()">
  <div *ngIf="!homeShimmer">
    <div class="mb-6 mb">
      <div *ngFor="let scream of screams; index as i">
        <app-scream [scream]="scream"></app-scream>
        <div *ngIf="screams.length - 1 > i">
          <mat-divider></mat-divider>
        </div>
      </div>
      <app-scream-shimmer *ngIf="screamShimmer"></app-scream-shimmer>
    </div>
  </div>
</div>
