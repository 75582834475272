import { SliderService } from './../../core/service/sliderService/slider.service';
import { SnackbarService } from './../../core/service/snackBar/snackbar.service';
import { ScreamService } from './../../core/service/screamService/scream.service';
import { HateRateSliderComponent } from './../../shared/hate-rate-slider/hate-rate-slider.component';
import {
  Component,
  ViewChild,
  OnInit,
  Inject,
  PLATFORM_ID,
  Input,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoaderService } from './../../core/service/loader/loader.service';
import { UploadImageComponent } from './../../shared/upload-image/upload-image.component';
import { StorageBlobService } from './../../core/service/storageBlobService/storage-blob.service';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { ScreamFieldComponent } from '../../shared/scream-field/scream-field.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { SaveDraftComponent } from './../../shared/save-draft/save-draft.component';
import { AddedImage } from './../../core/interfaces/added-Image';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { GoogleReviewComponent } from '../google-review/google-review.component';

@Component({
  selector: 'app-create-a-scream',
  templateUrl: './create-a-scream.component.html',
  styleUrls: ['./create-a-scream.component.scss'],
  providers: [Location],
})
export class CreateAScreamComponent implements OnInit {
  @ViewChild(HateRateSliderComponent) hateRateSlider!: HateRateSliderComponent;
  @ViewChild(UploadImageComponent) uploadImages!: UploadImageComponent;
  @ViewChild(ScreamFieldComponent) screamField!: ScreamFieldComponent;
  @Input() addedImages: AddedImage[] = [];
  value = 0;
  isSliderSelected = false;
  disabled = false;
  required = false;
  rows = 5;
  cols = 40;
  dropUp = false;
  userProfileImage: any;
  noOfUploadImages = 3;
  screamImages: any[] = [];
  imageKey: any;
  private readonly MAX_FILE_SIZE = 10000000;
  screamBrands: any[] = [];
  searchMention: any[] = [];
  isModifiedBrand: boolean = false;
  routeQueryParams$: any;
  privacy = [
    {
      value: 'public',
      icon: 'public',
      viewValue: 'Everyone',
      privacyHint: 'All users on Scremer',
      isPrivate: false,
    },
    {
      value: 'private',
      icon: 'lock_outline',
      viewValue: 'Only brand',
      privacyHint: 'Only the brand you tagged',
      isPrivate: true,
    },
  ];
  privacyControl = new FormControl(this.privacy[0]);
  public select = this.privacy[0];
  description = '';
  stripedScream = '';
  newContent: any | null;
  errorMessage: any;
  verifypostScreamResponseMessage: any;
  isMentionsSpinnerLoading: boolean = false;
  isScreamValid: boolean = false;
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  isChecked: boolean = true;
  platformId: Object;
  urlWithoutQueryParam: any;
  params: any = {};
  isBrandEnabledPrivateScream: boolean = false;
  constructor(
    public dialog: MatDialog,
    private readonly screamService: ScreamService,
    public loaderService: LoaderService,
    private snackBarService: SnackbarService,
    private storageBlobService: StorageBlobService,
    public dialogRef: MatDialogRef<CreateAScreamComponent>,
    private readonly dataTransferService: DataTransferService,
    private sliderService: SliderService,
    public router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.platformId = platformId;
  }
  ngOnInit(): void {
    this.userProfileImage = this.dataTransferService.getEditProfileImage();
    this.isSliderSelected = this.data?.isSliderSelected;
    this.value = this.sliderService.getSliderValue(this.data?.event);
    this.loadScreamDetailsFromLocalStorage();

    if (this.router.url.includes('private=true')) {
      this.privacyControl.setValue(this.privacy[1]);
    }
    this.isBrandEnabledPrivateScream =
      this.screamBrands.some(
        (brand: any) => brand.screamCreationPrivate === true,
      ) || this.router.url.includes('private=true');

    if (this.router.url?.includes('qrCode=true')) {
      this.activatedRoute.queryParams.subscribe((param: any) => {
        this.params.type = param['type'];
        this.params.fuid = param['flash'];
        this.params.isQrCode = true;
      });
    }
  }

  removeBrandIfModified() {
    let words: string[] = this.replaceBrandSpace()
      .replaceAll('\u00A0', ' ')
      .split(' ');
    if (!words || words.length === 0) {
      return this.screamBrands;
    }
    this.screamBrands = this.screamBrands.filter((brand: any) => {
      let found = false;
      words.forEach((word: string) => {
        if (word === '@' + brand.brandName.replaceAll(/ /g, '~')) {
          found = true;
          return;
        }
      });
      return found;
    });
    this.isBrandEnabledPrivateScream = this.screamBrands.some(
      (brand: any) => brand.screamCreationPrivate === true,
    );
    return this.screamBrands;
  }

  removeDuplicates(brand: any): boolean {
    return this.screamBrands?.some(
      (b: any) => b?.brandName === brand?.brandName,
    );
  }

  getSliderValue(event: any) {
    this.isSliderSelected = true;
    this.value = this.sliderService.getSliderValue(event);
    setTimeout(() => {
      this.screamField.inputField.nativeElement.focus();
    }, 10);
  }

  createScream(saveContent: boolean) {
    this.isBrandEnabledPrivateScream =
      this.screamBrands.some(
        (brand: any) => brand.screamCreationPrivate === true,
      ) || this.router.url.includes('private=true');

    const contentToSave = saveContent
      ? this.screamField?.inputField.nativeElement.innerHTML
      : this.stripHtmlTags(this.description)?.replaceAll('\u00A0', ' ');
    return {
      scream: contentToSave,
      privacy: this.isBrandEnabledPrivateScream
        ? this.isBrandEnabledPrivateScream
        : this.privacyControl.value?.isPrivate,
      hateRate: this.value,
      images: this.imageKey,
      screamBrands: this.screamBrands,
      screamImages: this.screamImages,
      ubid: this.screamBrands?.map?.((brand) => brand?.ubid),
      canAccessDetails: this.isChecked,
    };
  }

  stripHtmlTags(htmlString: any) {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
  }

  draft(saveDraft: boolean): void {
    if (this.isDraftValid()) {
      this.saveDraft(saveDraft);
    } else {
      this.dialog.closeAll();
      this.removeQrCodeQueryParam();
    }
  }

  onBrandRequestClick() {
    this.draft(true);
  }

  goBackToPreviousPage() {
    if (
      this.router.url?.includes('scream_it=true') ||
      this.router.url?.includes('/home') ||
      this.router.url?.includes('qrCode=true')
    ) {
      this.draft(false);
    } else {
      this.dialog.closeAll();
    }
  }

  isDraftValid(): boolean {
    return (
      (this.description?.trim()?.length > 0 && this.isSliderSelected) ||
      (this.screamImages?.length > 0 && this.isSliderSelected)
    );
  }

  saveDraft(brandRequest: boolean): void {
    const screamDetails = this.createScream(true);
    this.dialog.open(SaveDraftComponent, {
      width: '348px',
      panelClass: ['dialog-boxes'],
      disableClose: true,
      data: { screamDetails: screamDetails, brandRequest: brandRequest },
    });
  }

  loadScreamDetailsFromLocalStorage() {
    const scream =
      JSON?.parse(localStorage.getItem('screamDraft') as string) || '';
    if (scream) {
      if (scream.screamImages && Array.isArray(scream.screamImages)) {
        Promise.all(
          scream.screamImages.map(async (image: AddedImage) => {
            const response = await fetch(image.imageUrl);
            const blob = await response.blob();
            const file = new File([blob], image.imageName, {
              type: this.extractImageType(image.imageUrl),
            });
            const reconstructedImage = {
              imageFile: file,
              imageName: image.imageName,
              imageSize: image.imageSize,
              imageUrl: image.imageUrl,
            };
            this.screamImages.push(reconstructedImage);
          }),
        );
        this.screamBrands = scream.screamBrands;
        this.description = scream.scream;
        this.addedImages = this.screamImages;
        this.isChecked = scream.canAccessDetails;
        if (scream.privacy !== undefined) {
          this.privacyControl.setValue(
            scream.privacy ? this.privacy[1] : this.privacy[0],
          );
        } else {
          this.privacyControl.setValue(this.privacy[0]);
        }
      }
    }
  }

  extractImageType(imageUrl: string): string {
    const imageDataIndex = imageUrl.indexOf('data:');
    if (imageDataIndex !== -1) {
      const semicolonIndex = imageUrl.indexOf(';', imageDataIndex);
      if (semicolonIndex !== -1) {
        const typeStartIndex = imageDataIndex + 'data:'.length;
        return imageUrl.substring(typeStartIndex, semicolonIndex);
      }
    }
    return '';
  }

  onUploadImage(event: any) {
    this.screamImages = event;
  }

  scream() {
    if (this.screamBrands?.length - 1 >= 0) {
      this.loaderService.show();
      if (this.screamImages?.length > 0) {
        const formData = new FormData();
        let totalFileSize = 0;
        this.screamImages.forEach((file: any) => {
          formData.append('file', file.imageFile);
          totalFileSize += file.imageSize;
        });
        if (totalFileSize < this.MAX_FILE_SIZE) {
          this.storageBlobService.uploadImage(formData).subscribe({
            next: (data: any) => {
              this.imageKey = data.body.responseBody.join(', ');
              this.postScream();
            },
            error: () => {
              this.loaderService.hide();
              this.snackBarService.openSnackBar(
                'Internal server error. Please try again!',
                'error-snackbar',
              );
            },
          });
        } else {
          this.snackBarService.openSnackBar(
            'Your file exceeds maximum size of 10MB.',
            'error-snackbar',
          );
          this.loaderService.hide();
        }
      } else {
        this.postScream();
      }
    } else {
      this.snackBarService.openSnackBar(
        'Tag atleast one brand to create a scream',
        'error-snackbar',
      );
    }
  }

  clearAllQueryParams(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {},
      queryParamsHandling: '',
      replaceUrl: true,
    });
  }

  postScream() {
    if (this.dataTransferService.isLoggedin()) {
      this.replaceBrandSpaces();
      this.screamService
        .postScream(this.createScream(false), this.params)
        .subscribe({
          next: (data: any) => {
            if (data.body.responseCode == 201) {
              this.loaderService.hide();
              localStorage.removeItem('screamDraft');
              this.snackBarService.openViewScreamSnackBar(
                data.body?.responseBody?.suid,
              );
              this.clearAllQueryParams();
              this.dialog.closeAll();
              this.openGoogleReviewDialog(data.body?.responseBody);
            }
          },
          error: (data) => {
            this.loaderService.hide();
            if (data.status == 0) {
              this.snackBarService.openSnackBar(
                'Internal server error. Please try again!',
                'error-snackbar',
              );
            } else {
              this.snackBarService.openSnackBar(
                data.error.responseMessage,
                'error-snackbar',
              );
            }
          },
        });
    } else {
      const screamDetails = this.createScream(true);
      const serializedData = JSON.stringify(screamDetails);
      localStorage.setItem('screamDraft', serializedData);
      this.loaderService.hide();
      this.dialog.closeAll();
      if (this.activatedRoute && this.activatedRoute.queryParams) {
        this.routeQueryParams$ = this.activatedRoute.queryParams.subscribe(
          (params) => {
            if (params['qrCode']) {
              this.dataTransferService.setQrCodeUrl();
            }
          },
        );
      }
      this.router.navigate(['/login']);
    }
  }

  removeQrCodeQueryParam() {
    if (this.router.url?.includes('qrCode=true')) {
      this.urlWithoutQueryParam = this.router.url?.replace(
        /(\?|\&)qrCode=true/,
        '',
      );
      this.location.replaceState(this.urlWithoutQueryParam);
    } else if (this.router.url?.includes('scream_it=true')) {
      this.urlWithoutQueryParam = this.router.url?.replace(
        /(\?|\&)scream_it=true/,
        '',
      );
      this.location.replaceState(this.urlWithoutQueryParam);
      this.location.back();
    }
  }

  addImageEvt() {
    this.uploadImages.getAddImg().nativeElement.click();
  }

  setBrand(event: any) {
    this.removeDuplicates(event) ? null : this.screamBrands?.push(event);
    this.isBrandEnabledPrivateScream = this.screamBrands.some(
      (brand: any) => brand.screamCreationPrivate === true,
    );
  }

  setScream() {
    this.stripedScream = this.stripHtmlTags(
      this.screamField.inputField.nativeElement.innerHTML,
    );
    this.description = this.screamField.inputField.nativeElement.innerText;
    this.removeBrandIfModified();
  }

  disableScreamButton(): boolean {
    const trimmedScreamLength = this.stripHtmlTags(this.description)?.trim()
      ?.length;
    if (
      trimmedScreamLength > 0 &&
      trimmedScreamLength <= 500 &&
      this.isSliderSelected
    ) {
      return false;
    }
    return true;
  }
  replaceBrandSpaces() {
    this.screamBrands.forEach((brand) => {
      const brandName = brand.brandName;
      const regex = new RegExp(`(${brandName?.replace(' ', '\\s')})`, 'g');
      this.description = this.description?.replace(regex, (_, match) => {
        return match.includes(' ') ? match?.replace(/ /g, '~') : match;
      });
    });
  }

  replaceBrandSpace() {
    let replacedDescription = this.description;
    this.screamBrands.forEach((brand) => {
      const brandName = brand.brandName;
      const replacedBrandName = brandName.replace(/ /g, '~');
      const regex = new RegExp(`(${brandName.replace(' ', '\\s')})`, 'g');
      replacedDescription = replacedDescription?.replace(regex, (_, match) => {
        return match.includes(' ') ? replacedBrandName : match;
      });
    });
    return replacedDescription;
  }

  openGoogleReviewDialog(response: any) {
    let url = response?.googleReviewUrl;
    let scream = this.createScream(false).scream;
    if (url !== '' && url !== undefined) {
      this.dialog.open(GoogleReviewComponent, {
        width: '348px',
        panelClass: ['dialog-boxes'],
        disableClose: true,
        data: { url: url, brandName: this.extractBrandName(), scream: scream },
      });
    }
  }

  extractBrandName(): string {
    let brandName = '';
    const url = this.router.url;
    const brandRegex = /\/brand\/([^?]+)/;
    const match = url.match(brandRegex);

    if (match && match[1]) {
      brandName = decodeURIComponent(match[1]);
      return brandName;
    } else {
      console.error('Brand name not found in URL');
      return '';
    }
  }
}
