import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Rating } from 'src/app/core/interfaces/rating';
import { BottomNavBarService } from 'src/app/core/service/bottomNavBar/bottom-nav-bar.service';
import { BrandService } from 'src/app/core/service/brandService/brand.service';
import { DataTransferService } from 'src/app/core/service/data-transfer/data-transfer.service';
import { ScreamService } from 'src/app/core/service/screamService/scream.service';
import { SliderService } from 'src/app/core/service/sliderService/slider.service';
import { GoogleReviewComponent } from 'src/app/scream/google-review/google-review.component';
import { HateRateSliderComponent } from '../hate-rate-slider/hate-rate-slider.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
  selector: 'app-review-rating-user-flow',
  templateUrl: './review-rating-user-flow.component.html',
  styleUrl: './review-rating-user-flow.component.scss',
})
export class ReviewRatingUserFlowComponent implements OnInit, AfterContentInit {
  @ViewChild(HateRateSliderComponent) hateRateSlider!: HateRateSliderComponent;
  homeShimmer = false;
  value = 0;
  pageIndex = 0;
  pageSize = 10;
  totalRatings: any;
  screamShimmer: any;
  errorMessage: any;
  userImage = 'userImage';
  brandName: any;
  isContentVisible!: boolean;
  ratingPageIndex = 0;
  isFollow!: boolean;
  closedCount: any;
  @Input() rating: any;
  @Input() profile: any;
  reviewRatings: Rating[] = [];
  searchControl = new FormControl('');
  staticBranches!: string[];
  branches!: any;
  isDropdownOpen = false;
  isLoading: boolean = false;
  isBranchesExist: boolean = false;
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  isUserLoggedIn = this.dataTransferService?.isLoggedin();
  isBusinessLoggedIn = this.dataTransferService?.isBusinessLoggedin();
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  showMoreButton!: boolean;
  isSliderSelected = false;
  params: any = {};
  screamEmotion = [
    'frustrated',
    'mad',
    'furious',
    'angry',
    'disappointed',
    'satisfied',
    'good',
    'happy',
    'joyful',
    'delighted',
  ];
  foodTaste: number = 0;
  service: number = 0;
  shopLook: number = 0;
  overall: number = 0;
  suggestion: string = '';
  isChecked: boolean = false;
  isstarchecked: boolean = false;
  displayedStatus: any;
  ratingsscale: any;
  userProfileImage: any;
  suggestionText: string = '';
  showSuggestion: boolean = false;
  currentUserName: any;
  selectedBranch: string = '';
  @ViewChild('suggestion', { static: false })
  suggestionTextarea!: CdkTextareaAutosize;

  resizeTextarea(): void {
    setTimeout(() => {
      this.suggestionTextarea.resizeToFitContent(true);
    });
  }

  constructor(
    public brandService: BrandService,
    public router: Router,
    private readonly bottomService: BottomNavBarService,
    public dialog: MatDialog,
    public dataTransferService: DataTransferService,
    private sliderService: SliderService,
    private screamService: ScreamService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ReviewRatingUserFlowComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.showSuggestion = data.showSuggestion;
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.bottomService.hide();
    }, 0);
  }

  screenWidth: number = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    if (this.router.url?.includes('qrCode=true')) {
      this.activatedRoute.queryParams.subscribe((param: any) => {
        this.params.isQrCode = true;
        this.params.fuid = param['flash'];
        this.params.type = param['type'];
      });
    }
  }

  setFeeling() {
    return 'is feeling';
  }

  ratingQuestions = [
    { label: '1. Is our food tasty?', key: 'tasteRating', value: 0 },
    { label: '2. How is the service?', key: 'serviceRating', value: 0 },
    { label: '3. How does our shop look?', key: 'ambienceRating', value: 0 },
    {
      label: '4. How is the overall experience?',
      key: 'overallRating',
      value: 0,
    },
  ];

  payload() {
    const reviewPayload = {
      tasteRating: this.foodTaste,
      serviceRating: this.service,
      overallRating: this.overall,
      ambienceRating: this.shopLook,
      hateRate: this.value,
      suggestion: this.suggestion,
      flashType: this.params.flashType,
      qrCode: this.params.qrCode,
      googleReview: this.rating?.googleReview,
    };

    if (
      reviewPayload.tasteRating > 0 &&
      reviewPayload.serviceRating > 0 &&
      reviewPayload.ambienceRating > 0 &&
      reviewPayload.overallRating > 0
    ) {
      this.isstarchecked = true;
    } else {
      this.isstarchecked = false;
    }
  }

  checkAllStarsGiven() {
    this.isstarchecked =
      this.foodTaste > 0 &&
      this.service > 0 &&
      this.shopLook > 0 &&
      this.overall > 0;
    this.cdr.detectChanges();
  }

  continue() {
    this.checkAllStarsGiven();

    if (!this.isstarchecked) {
      return;
    }

    this.showSuggestion = true;

    if (window.innerWidth > 576) {
      this.dialogRef.updateSize('348px', '317px');
    }
  }

  submit() {
    const reviewPayload = {
      tasteRating: this.foodTaste,
      serviceRating: this.service,
      overallRating: this.overall,
      ambienceRating: this.shopLook,
      hateRate: this.value,
      suggestion: this.suggestionText || null,
      flashType: this.params.flashType,
      qrCode: this.params.qrCode,
      googleReview: this.rating?.googleReview,
    };

    this.screamService.postRating(reviewPayload, this.params).subscribe({
      next: (response: any) => {
        if (response.body.responseCode === 201) {
          this.clearAllQueryParams();
          this.dialog.closeAll();
          this.openGoogleReviewDialog(response.body?.responseBody);
        }
      },
    });
  }

  goBackToPreviousPage() {
    this.dialog.closeAll();
  }

  getSliderValue(event: any) {
    this.isSliderSelected = true;
    this.value = this.sliderService.getSliderValue(event);
  }

  skipSuggestion() {
    this.suggestionText = '';
    this.submit();
  }

  showSuggestionBox() {
    this.showSuggestion = true;
  }

  extractBrandName(): string {
    let brandName = '';
    const url = this.router.url;
    const brandRegex = /\/brand\/([^?]+)/;
    const match = url?.match(brandRegex);

    if (match && match[1]) {
      brandName = decodeURIComponent(match[1]);
      return brandName;
    } else {
      console.error('Brand name not found in URL');
      return '';
    }
  }

  openGoogleReviewDialog(response: any) {
    let url = response?.googleReviewUrl;

    if (url !== '' && url !== undefined) {
      this.dialog.open(GoogleReviewComponent, {
        width: '348px',
        panelClass: ['dialog-boxes'],
        disableClose: true,
        data: { url: url, brandName: this.extractBrandName() },
      });
    }
  }

  clearAllQueryParams(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {},
      queryParamsHandling: '',
      replaceUrl: true,
    });
  }

  navigateToProfile() {
    const username =
      this.rating?.loginResponseDTO?.userName || this.profile?.userName;
    if (this.isUserLoggedIn) {
      if (username !== this.currentUserName) {
        this.router.navigate([`/${username}`]);
        window.scroll(0, 0);
      } else {
        this.router.navigate(['/my-profile']);
        window.scroll(0, 0);
      }
    }
  }
}
