<div *ngIf="!businessLoggedIn()">
  <mat-toolbar
    *ngIf="
      (headerService.visible && loggedIn()) ||
      (!loggedIn() && router.url.startsWith('/home'))
    "
    class="background-color w-100 d-flex justify-content-between py-2 display-only-mobile fixed header-size"
  >
    <img
      class="pointer profile-image-40 rounded-object background-white-color"
      (click)="goToMyProfile()"
      [src]="
        userProfileImage ? blobStorageLink + userProfileImage : defaultImage
      "
      alt="User-Image"
    />
    <img
      (click)="goToHome()"
      class="white-color pointer"
      src="assets/images/Scremer-Logo.svg"
      alt="scremer-logo.svg"
    />
    <button mat-icon-button (click)="toggleSearchCard()">
      <mat-icon class="material-icons-outlined fs-24 white-color pointer"
        >search</mat-icon
      >
    </button>
  </mat-toolbar>

  <mat-toolbar
    *ngIf="
      headerService.visible && !loggedIn() && !router.url.startsWith('/home')
    "
    class="header-size center background-white-color w-100 active-link fixed"
  >
    <div class="container p-0 d-flex justify-content-between max-width-header">
      <div class="pointer" (click)="goToHome()">
        <img src="assets/images/ScremerBlue.svg" alt="scremer-logo.svg" />
      </div>
      <div
        class="d-flex justify-content-around align-items-center display-desktop-only"
      >
        <div
          class="d-flex mx-3 align-items-center px-4 py-1 business pointer"
          (click)="goToBusinessLanding()"
        >
          <mat-icon class="material-icons-outlined pointer fs-18 mt-2"
            >add_business</mat-icon
          >
          <div class="fw-700 fs-16 lato-font-family">Scremer for business</div>
        </div>
        <button
          (click)="login()"
          mat-raised-button
          class="btn hover-color fw-700 lato-font-family fs-16"
        >
          <div class="active-link">Log in</div>
        </button>
        <div class="ms-3">
          <button
            (click)="signUp()"
            mat-raised-button
            class="btn btn-primary background-color fw-700 fs-16 lato-font-family white-color"
          >
            Sign up
          </button>
        </div>
      </div>
      <div class="display-mobile-only">
        <mat-icon
          class="fs-24 material-icons-outlined pointer mt-3 carousel-indicators"
          >menu</mat-icon
        >
      </div>
    </div>
  </mat-toolbar>

  <mat-toolbar
    *ngIf="
      (headerService.visible && loggedIn()) ||
      (!loggedIn() && router.url.startsWith('/home'))
    "
    class="header-size background-color w-100 d-flex px-3 py-2 display-none-header display-inline-header fixed"
  >
    <div class="container p-0 max-width d-flex justify-content-between">
      <div class="fixed-width col-12 col-sm-4 col-md-3 col-lg-3">
        <img
          (click)="goToHome()"
          class="white-color fw-700 pointer px-3"
          src="assets/images/Scremer-Logo.svg"
          alt="scremer-logo.svg"
        />
      </div>
      <div
        class="background-white-color search-div d-flex align-items-center col-12 col-sm-8 col-md-9 col-lg-6 p-0 layout-max-width px-3 py-2"
        (click)="toggleSearchCard()"
      >
        <div class="icon-size">
          <mat-icon
            class="material-icons-outlined pointer fs-24"
            [ngClass]="{
              'black-color': searchControl.value,
              'scream-icon-color': !searchControl.value,
            }"
            >search</mat-icon
          >
        </div>

        <input
          type="text"
          placeholder="Search scremer"
          class="fw-400 fs-16 p-0 mx-2 w-100"
          [formControl]="searchControl"
          (keyup.enter)="searchResults($event)"
          (ngModelChange)="searchResults($event)"
        />
        <div class="icon-size" *ngIf="!isSearchEmpty">
          <mat-icon class="pointer fs-24" (click)="clearAllSearchResults()"
            >clear</mat-icon
          >
        </div>
      </div>
      <div
        class="d-none d-lg-block col-12 col-lg-3 p-0 fixed-width justify-content-end"
      >
        <img
          class="pointer profile-image-40 rounded-object carousel-indicators"
          (click)="goToMyProfile()"
          [src]="
            userProfileImage ? blobStorageLink + userProfileImage : defaultImage
          "
          alt="User-Image"
        />
      </div>
    </div>
  </mat-toolbar>
  <div *ngIf="showSearchCard">
    <app-search (resetEvt)="resetSearch()"></app-search>
  </div>
</div>
